import { Injectable } from '@angular/core';
import { getLocaleCurrencySymbol, getLocaleCurrencyName } from '@angular/common';
import { CONTENT_CL } from '../content/content-cl';
import { CONTENT_PE } from '../content/content-pe';
import { CONTENT_CO } from '../content/content-co';
import { CONTENT_ME } from '../content/content-me';
import { CONTENT_AR } from '../content/content-ar';

@Injectable()
export class LocaleService {

    locale: string;
    symbol: string;
    currencyName: string;
    content;
    currencyCode;
    TLD = 'pe'; // window.location.href.split('.')[2].split('/')[0].toLowerCase();
    taxes: string;

    selectorGTM = {
        GTMcl: 'GTM-M9Z363Q',
        // GTMco: '',
        // GTMpe: ''
    };

    selectorGtag = {
        clGtag: 'UA-86672331-1',
        //coGtag: '',
        //peGtag: ''
    };

    constructor(
    ) {
        this.locale = this.getLocale(this.TLD);
        this.symbol = getLocaleCurrencySymbol(this.locale);
        this.currencyName = getLocaleCurrencyName(this.locale);
        // this.currencyCode = getLocaleCurrencCode(this.locale);
        this.taxes = this.getTaxez();
        this.getContent();
    }

    getLocale(tld: string): string {
        const TLD = tld.toLowerCase();
        if (TLD === 'net') {
            return 'en-US';
        } else if (TLD === 'cl' || TLD === 'ar' || TLD === 'mx' ||
            TLD === 'ec' || TLD === 'co' || TLD === 'pe') {
            return 'es-' + (TLD.toUpperCase());
        } else {
            return 'es-CL';
        }
    }
    getContent() {
        this.content = CONTENT_CL;
        if (this.locale === 'es-PE') {
            this.content = CONTENT_PE;
        } else if (this.locale === 'es-CO') {
            this.content = CONTENT_CO;
        } else if (this.locale === 'es-MX') {
            this.content = CONTENT_ME;
        } else if (this.locale === 'es-AR') {
            this.content = CONTENT_AR;
        }
    }
    getTaxez() {
        if (this.TLD === 'cl') {
            return '+ IVA';
        } else if (this.TLD === 'pe') {
            return '+ IGV';
        } else {
            return '';
        }
    }

    getGTM(): string {
        let element: string;
        switch (this.locale) {
            // case 'es-CO':
            //     element = this.selectorGTM.GTMco;
            //     break;
            // case 'es-PE':
            //     element = this.selectorGTM.GTMpe;
            //     break;
            // case 'es-MX':
            //     break;
            // case 'es-AR':
            //     break;
            case 'es-CL':
                element = this.selectorGTM.GTMcl;
                break;
            default:
                element = '';
        }
        return element;
    }
    //Selection Google Analytics ID
    getGtag(): string {
        let element: string;
        switch (this.locale) {
            // case 'es-CO':
            //     element = this.selectorGtag.coGtag;
            //     break;
            // case 'es-PE':
            //     element = this.selectorGtag.peGtag;
            //     break;
            // case 'es-MX':
            //     break;
            // case 'es-AR':
            //     break;
            case 'es-CL':
                element = this.selectorGtag.clGtag;
                break;
            default:
                element = '';
        }
        return element;
    }
}
